/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/api';
import $ from 'jquery';

$.fn.extend({
  addToCart() {
    const element = this;
    const url = $(element).attr('action');
    const redirectUrl = $(element).data('redirect');
    const validationElement = $('#sylius-cart-validation-error');

    element.api({
      method: 'POST',
      on: 'submit',
      cache: false,
      url,
      beforeSend(settings) {
        /* eslint-disable-next-line no-param-reassign */
        settings.data = element.serialize();

        return settings;
      },
      onSuccess() {
        validationElement.addClass('hidden');
        //window.location.href = redirectUrl;
        enhancedEcommerceAddToCart(gtmAddToCartProductInfo, redirectUrl); // !!! BLACKPIG: GTMEE
      },
      onFailure(response) {
        validationElement.removeClass('hidden');
        let validationMessage = '';

        Object.entries(response.errors.errors).forEach(([, message]) => {
          validationMessage += message;
        });
        validationElement.html(validationMessage);
        $(element).removeClass('loading');
      },
    });
  },
});

// try adding remove-from-cart here for now
$('form button.sylius-cart-remove-button').on('click',function(e){
    var $this = $(this);
    if($this.hasClass('gtmee')) {
        // carry on with click and therefore SUBMIT
    } else {
        var gtmRemoveFromCartProductInfo = {
            'name': $this.attr('data-name'),
            'id': $this.attr('data-id'),
            'price': $this.attr('data-price'),
            'brand': $this.attr('data-brand'),
            'category': $this.attr('data-category'),
            'variant': $this.attr('data-variant'),
            'quantity': $this.attr('data-quantity')
        };
        enhancedEcommerceRemoveFromCart(gtmRemoveFromCartProductInfo, function() {
            $this.addClass('gtmee');
            $this.trigger('click'); // click again, this time it will really submit
        });
        e.stopPropagation(); // DO NOT submit yet
        return false;
    }
});
